<template>
  <div class="navbar bg-base-200">
    <div class="navbar-start">
      <a class="btn btn-ghost normal-case text-3xl"
        ><img src="../assets/logo.png" style="width: 60px" /> สามเหลี่ยมออโต้</a
      >
    </div>

    <div class="navbar-center">
      <a href="/" class="btn btn-ghost normal-case text-xl">หน้าแรก</a>
      <a href="/search" class="btn btn-ghost normal-case text-xl">ค้นหารถ</a>
      <!-- <a class="btn btn-ghost normal-case text-xl">รู้จักสามเหลี่ยมออโต้</a> -->
      <a href="/promotions" class="btn btn-ghost normal-case text-xl"
        >โปรโมชั่น</a
      >
      <a href="/slmSales" class="btn btn-ghost normal-case text-xl"
        >ติดต่อเรา</a
      >
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
