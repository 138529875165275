<template>
  <RouterView />
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import from './components/HelloWorld.vue'

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
};
</script>

<style></style>
